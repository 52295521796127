.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: #ffffff;
  border-radius: 24px;
  /* border-radius: 32px; */
  box-shadow: 2px 4px 120px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 558px;
  display: flex;
  flex-direction: column;
}

.image {
  width: 362.5px;
  height: 241.5px;

  border-radius: 24px 24px 0px 0px;
  box-shadow: 2px 4px 120px rgba(0, 0, 0, 0.08);
}

.instructionContainer {
  padding: 0px 24px 12px 24px;
}

.step {
  margin: 24px 0px 12px 0px;
}
