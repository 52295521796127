.item {
  height: 488px;
  position: relative;
}

.favourite {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  color: #ccc;
  background-color: #ffffff;
  border-radius: 100%;
  right: 20px;
  top: 20px;
}

.image img {
  width: 362px;
  height: 241px;
}

.content {
  padding: 16px 24px 24px 24px;
  height: 244px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heading {
  font-size: 28px;
  letter-spacing: 0.25px;
  margin-bottom: 6px;
}

.subheading {
  font-size: 20px;
  font-weight: normal;
  /* font-style: italic; */
  margin-bottom: 20px;
}

.durationAlign {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  margin-bottom: 24px;
}

.actions {
  margin-top: auto;
}

.actions a {
  text-decoration: none;
  color: #1d1d1b;
}

.actions button {
  display: flex;
  font: inherit;
  border: none;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: auto;
  top: 0;
}

.button {
  padding: 8px 16px;
  border: 3px solid #1d1d1b;
  border-radius: 10px;
}

/* MEDIA QUERIES */

@media (max-width: 1199px) {
  .item {
    height: 454px;
  }

  .image img {
    width: 296px;
    height: 197.33px;
  }

  .content {
    height: 258px;
  }

  .heading {
    font-size: 26px;
  }

  .subheading {
    font-size: 18px;
  }

  .durationAlign {
    font-size: 16px;
  }

  .actions button {
    font-size: 16px;
  }
}

@media (max-width: 999px) {
  .item {
    height: 488px;
  }

  .image img {
    width: 362px;
    height: 241px;
  }

  .content {
    height: 244px;
  }

  .heading {
    font-size: 28px;
  }

  .subheading {
    font-size: 20px;
  }

  .durationAlign {
    font-size: 18px;
  }

  .actions button {
    font-size: 18px;
  }
}

@media (max-width: 999px) {
  .item {
    height: 484px;
  }

  .image img {
    width: 356px;
    height: 237.33px;
  }
}

@media (max-width: 799px) {
  .item {
    height: 454px;
  }

  .image img {
    width: 296px;
    height: 197.33px;
  }

  .content {
    height: 258px;
  }

  .heading {
    font-size: 26px;
  }

  .subheading {
    font-size: 18px;
  }

  .durationAlign {
    font-size: 16px;
  }

  .actions button {
    font-size: 16px;
  }
}
