.card {
  background-color: #ffffff;
  border-radius: 24px;
  /* border-radius: 32px; */
  box-shadow: 2px 4px 120px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 488px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1199px) {
  .card {
    height: 454px;
  }
}

@media (max-width: 999px) {
  .card {
    height: 484px;
  }
}

@media (max-width: 799px) {
  .card {
    height: 454px;
  }
}
