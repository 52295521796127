/* 
*/
/* GENERAL */
/* 
*/

.subheading {
  font-size: 38px;
  margin-bottom: 48px;
  text-align: center;
}

/* 
*/
/* HEADER */
/* 
*/

.container {
  padding: 0 32px;
}

.headerSection {
  background-color: #fff;
  border-radius: 32px 32px 0px 0px;
  padding: 12px 32px;
}

.textContainer {
  padding: 32px 32px 48px 32px;
}

.heading {
  font-size: 56px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.secondaryheading {
  font-size: 32px;
  margin-bottom: 30px;
  font-weight: normal;
}

.durationNumber {
  font-size: 30px;
  font-weight: bold;
}

.durationLabel {
  font-size: 20px;
  font-weight: normal;
}

.imageContainer {
  height: 350px;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  border-radius: 42px 8px 142px 24px;
  /* border-radius: 2px 2px 112px 24px; */
}

.image {
  width: 1072px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* 
*/
/* INFORMATION SECTION */
/* 
*/

.informationSection {
  background-color: #fff;
  margin-bottom: 64px;
  padding: 64px 32px 32px 32px;
  border-radius: 0px 0px 32px 32px;
}

/* 
*/
/* INGREDIENTS */
/* 
*/

.ingredientGrid {
  display: grid;
  grid-template-columns: repeat(5, 195px);
  row-gap: 48px;
  column-gap: 24px;
  margin-bottom: 72px;
  padding-bottom: 72px;
  border-bottom: 3px solid #f5f5f5;
}

/* 
*/
/* UTENSILS */
/* 
*/

.utensilsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 72px;
  padding-bottom: 72px;
  border-bottom: 3px solid #f5f5f5;
}

/* 
*/
/* UTENSILS */
/* 
*/
.nutritionContainer {
  display: flex;
  justify-content: center;
}

/* 
*/
/* INSTRUCTIONS */
/* 
*/

.instructionsSection {
  margin-bottom: 100px;
}

.instructionsGrid {
  display: grid;
  grid-template-columns: repeat(3, 362.5px);
  grid-template-rows: repeat(1, 552px);
  column-gap: 24px;
  row-gap: 48px;
  list-style: none;
  margin: 0;
  padding: 0;
}
