* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Sans Condensed", sans-serif;
  background-color: #ffffff;
  color: #1d1d1d;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  overflow-x: hidden;
  transition: 0.4s;
  background-color: #f5f5f5;
}

.container {
  max-width: 1200px;
  padding: 0 32px;
  margin: 0 auto;
}

.form {
  max-width: 750px;
  padding: 0 32px;
  margin: 0 auto;
  margin-top: 90px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 362px);
  grid-template-rows: repeat(1, 488px);
  column-gap: 24px;
  row-gap: 48px;
  list-style: none;
  margin: 48px 32px 128px 32px;
  padding: 0;
}

/* MEDIA QUERIES */

@media (max-width: 1199px) {
  .container {
    max-width: 1000px;
  }

  .grid {
    grid-template-columns: repeat(3, 296px);
    grid-template-rows: repeat(1, 454px);
    /* change in card.module and recipeitem.module */
  }
}

@media (max-width: 999px) {
  .container {
    max-width: 800px;
  }

  .grid {
    grid-template-columns: repeat(2, 356px);
    grid-template-rows: repeat(1, 484px);
    row-gap: 36px;
  }
}

@media (max-width: 799px) {
  .container {
    max-width: 680px;
  }

  .grid {
    grid-template-columns: repeat(2, 296px);
    grid-template-rows: repeat(1, 454px);
  }
}
