.grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 96px;
  margin-bottom: 72px;
}

.subheading {
  margin-bottom: 24px;
  font-size: 24px;
  text-align: center;
}

.container {
  width: 298px;
}

.block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 6px;
}

.block:last-child {
  margin-bottom: 0px;
}

.title {
  font-weight: bold;
}
