.main {
  margin: 32px auto;
  max-width: 1200px;
  /* width: 100%; */
}

@media (max-width: 1199px) {
  .main {
    max-width: 1000px;
  }
}

@media (max-width: 999px) {
  .main {
    max-width: 800px;
  }
}

@media (max-width: 799px) {
  .main {
    max-width: 680px;
  }
}
