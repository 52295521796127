.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  /* padding: 0 32px; */
  font-family: inherit;
  max-width: 1200px;
  margin: 32px auto;
  padding: 0 32px;
}

.logo {
  font-size: 38px;
  font-weight: bold;
}

.header ul {
  display: flex;
  list-style: none;
  font-size: 22px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 16px;
  padding: 8px 16px;
}

.header li:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.header a {
  text-decoration: none;
  color: #1d1d1d;
}

/* --------------------------------------------- */

@media (max-width: 1199px) {
  .logo {
    font-size: 34px;
  }

  .header {
    max-width: 1000px;
  }

  .header ul {
    font-size: 20px;
  }

  .header li {
    margin: 10px;
  }
}

@media (max-width: 999px) {
  .header {
    max-width: 800px;
  }
}
